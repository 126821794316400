import React from 'react'
import color from '@/constants/color'
import { css } from '@emotion/react'
import { mq } from '../../media/media'

export type CompanyData = {
  term: string
  description: string
}

export type CompanyDataProps = {
  definitionList: CompanyData[]
  className?: string
}

export const CompanyDataList = ({
  definitionList,
  className
}: CompanyDataProps): JSX.Element => (
  <dl className={className}>
    {definitionList.map(({ term, description }, index) => (
      <div key={index} css={cssDescriptionPare}>
        <dt css={cssDataTerm}>{term}</dt>
        <dd css={cssDataDescription}>{description}</dd>
      </div>
    ))}
  </dl>
)

const cssDescriptionPare = css`
  padding: 24px 0;
  font-size: 14px;
  color: ${color.black1};
  letter-spacing: 1.4px;
  border-bottom: 1px dotted ${color.gray1};
  ${mq.pc} {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
  }
  &:first-of-type {
    padding-top: 0;
  }
`

const cssDataTerm = css`
  margin-bottom: 12px;
  font-weight: 300;
  ${mq.pc} {
    width: 230px;
    padding-right: 40px;
    margin-bottom: 0;
    font-weight: 600;
  }
`

const cssDataDescription = css`
  flex: 1;
  font-weight: 600;
  white-space: pre-line;
  ${mq.pc} {
    font-weight: 400;
  }
`
