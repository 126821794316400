import { HeaderNavListType } from '@/typedir/types'

export const headerNavList: HeaderNavListType[] = [
  { url: '/group/rebootus', label: 'トップ', labelEnglish: 'TOP' },
  {
    url: '/group/rebootus#company',
    label: '会社情報',
    labelEnglish: 'ABOUT US'
  },
  {
    url: 'https://rebootus.co.jp/form',
    label: 'お問い合わせ',
    isContact: true
  }
]
