import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2022.04.18',
    text: 'Relicホールディングスが、事業再生や再成長を支援する「リノベーションスタジオ事業」及び事業のM&A・売買や再配置を支援する「リロケーションスタジオ事業」を展開する戦略子会社「Reboo＋」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000113.000016318.html'
  }
  // {date: "2099.09.28", text: "テスト", url: "https://prtimes.jp/main/html/rd/p/000000085.000016318.html"}
]
