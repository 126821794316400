import React from 'react'
import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import color from '@/constants/color'
import { Anchor } from '@/components/common/anchor'

export type GroupAndServiceDataProps = {
  title: string
  url: string
  isTargetBlank?: boolean
}

export const GroupAndServiceData: React.FC<GroupAndServiceDataProps> = ({
  title,
  url,
  isTargetBlank = true
}) => {
  return (
    <li css={cssItem}>
      <div css={cssTitle}>{title}</div>
      <Anchor isTargetBlank={isTargetBlank} href={url} css={cssLink}>
        {url}
      </Anchor>
    </li>
  )
}

const cssItem = css`
  position: relative;
  box-sizing: border-box;
  display: block;
  padding: 24px 10px;
  border-bottom: 1px dotted ${color.gray1};

  ${mq.pc} {
    padding: 32px 10px;
    font-size: 1.7rem;
  }
`

const cssTitle = css`
  margin-bottom: 5px;
  font-size: 1.6rem;
  letter-spacing: 0.03em;

  ${mq.pc} {
    min-width: 10em;
    font-size: 1.7rem;
  }
`

const cssLink = css`
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
