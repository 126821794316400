import React from 'react'
import { Helmet } from 'react-helmet'
import '@/styles/scalehack/styleCo.scss'

import favicon from '@/images/rebootus/favicon.ico'
import appleIcon from '@/images/rebootus/apple-touch-icon.png'

type Props = {
  title: string
  url: string
}

export const RebootusHead = (props: Props): JSX.Element => {
  const { title, url } = props

  return (
    <Helmet>
      <html lang="ja" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <title>{title}</title>
      <meta
        name="description"
        content="Reboo+では、「事業の再起動＋αの循環で、再挑戦を民主化する。」をミッションに、事業/プロダクトの評価や撤退/ピボット、再生・再成長や、M&Aや事業の譲渡/流通の総合支援事業を展開しています。"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://relic-holdings.co.jp/${url}`} />
      <meta
        property="og:image"
        content="https://relic-holdings.co.jp/OGP_1200*630_Rebootus.png"
      />
      <meta
        property="og:description"
        content="Reboo+では、「事業の再起動＋αの循環で、再挑戦を民主化する。」をミッションに、事業/プロダクトの評価や撤退/ピボット、再生・再成長や、M&Aや事業の譲渡/流通の総合支援事業を展開しています。"
      />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:site_name" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon-precomposed"
        href={appleIcon}
        sizes="180x180"
      />
    </Helmet>
  )
}
